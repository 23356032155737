import { React } from 'react';

import styles from './Header.module.scss';

const Header = () => {
    return (
        <header className={styles.appHeader}>
            Header
        </header>
    )
};

export default Header
