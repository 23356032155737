import { React } from 'react';

import styles from './Share.module.scss';

const Share = () => {
    return (
        <div className={styles.pageWrapper}>
            Hey there
        </div>
    );
};

export default Share;