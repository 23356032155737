import React from 'react';
import { Router, Switch, Route } from 'react-router-dom';

import { history } from './_utils/history';
import './styles/App.scss';

import Header from './_components/header/Header';
import Share from './pages/share/Share';

export default function App() {
    return (
        <Router history={history}>
            <Header />

            <section className='container'>
                <Switch>
                    <Route exact path="/">
                        <Share />
                    </Route>
                </Switch>
            </section>
        </Router>
    );
}
